import React from "react"
import Layout from "./layout"
import { RoundButton, ButtonRow } from "./button"
import { Overlay } from "./overlay"
import GlitchBackgroundPost from "./glitch-background-post"

export default () => (
  <Layout menuHidden={false}>
    <GlitchBackgroundPost />
    <Overlay animate="undefined" exit="undefined">
      <h1>The text you just heard was real. But Nixon never delivered it.</h1>
      <h1>Our film is a deepfake.</h1>
      <ButtonRow>
        <RoundButton to="/film?onlySpeech">Replay Speech</RoundButton>
        <RoundButton to="/resources">Learn More</RoundButton>
      </ButtonRow>
    </Overlay>
  </Layout>
)
